import React from "react";
 

import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import imgStyles from "assets/style/image.js"
import { Link } from "@material-ui/core";

function CommunityPage() {
  const UseStyles = makeStyles(styles);
  const UseImgStyles = makeStyles(imgStyles);

  const classes = UseStyles();
  const imgClass = UseImgStyles();

  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          {community && <div style={{ color: 'black', padding: '50px', margin: '10vw auto' }} >
            <h2>Wspólnota {community.name}</h2>
            <img src={`/zdjecia/Wspólnoty/${community.name}.jpg`} alt="" style={{ maxWidth: '100%' }} />
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly' }}>
              <div >
                <img src={`/zdjecia/Księża/${community.priest}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Opiekun: </h3>
                <h3>{community.priest}</h3>
              </div>
              <div >
                <img src={`/zdjecia/Liderzy/${community.lider}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Lider: </h3>
                <h3>{community.lider}</h3>
              </div>
            </div>
            {community.meetings &&
              <>
                <h3 style={{ fontWeight: 'bold' }}>Spotkania: </h3>
                <h3>{community.meetings}</h3>
              </>
            }
            <h3 style={{ fontWeight: 'bold' }}>O wspólnocie: </h3>
            <h3>{community.description}</h3>
            {community.comming && community.comming.map((item, idx) => (
              <h3 key={idx}>{item}</h3>))}
            {community.news &&
              <>
                <h3>Aktualności: </h3>
                {community.news.map((item, idx) => (
                  <Link key={idx} href={`/${item.link}`} id={`#${item.link}`}>
                    <img src={`/${item.posterUrl}`} className={imgClass.img} alt={item.alt}/>
                  </Link>
                )
                )}
              </>
            }
          </div>}
        </div>
      </div>
    </Layout>
  );
}

 
  const community =     {
    "url": "uwielbienia",
    "name": "UWIELBIENIA",
    "lider": "Małgorzata Kała",
    "priest": "ks. Piotr Nowicki",
    "meetings": "Parafia pw. Wniebowzięcia NMP w Lewinie Brzeskim, piątek godz. 18:40",
    "description": "Nasza wspólnota powstała w marcu 2013 roku. Założyli ją Jadwiga i Grzegorz Fornal oraz Małgorzata i Przemysław Kała. Założyciele posiadali doświadczenie wspólnot diecezji opolskiej, dlatego nowa wspólnota gościnnie formuje się w ramach naszej diecezji, choć terytorialnie znajduje się w archidiecezji wrocławskiej. Na spotkaniach gromadzi się od 15 do 20 osób. Zazwyczaj modlimy się w kościele parafialnym. W naszym sercu jest wielkie pragnienie rozwoju modlitwy wstawienniczej oraz powrotu do grup dzielenia. "
  }
   

export default CommunityPage;
